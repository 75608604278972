import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Bootstrap from '../components/bootsrap';
import { loadableP5 as P5Wrapper } from '../components/loadablep5';
import sketch from '../components/404/sketch';

const About = () => {
  return (
    <>
      <Helmet>
        <title>Page not found | Maneesh Chiba</title>
      </Helmet>
      <Bootstrap>
        <P5Wrapper sketch={sketch}/>
      </Bootstrap>
    </>
  );
};

export default About;
