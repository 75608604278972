import type from '../../../static/fonts/SpaceMono-Bold.ttf';

export default function Sketch(p) {
  const W = window.innerWidth;
  const H = window.innerHeight;
  let canvas;
  let font;
  const fontsize = 172;
  const textToDisplay = '4O4';
  const particleSystem = [];

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function getRandomColour() {
    const colors = ['#ffffff'];
    const selection = getRandomInt(0, (colors.length - 1));
    return colors[selection];
  }

  function Particle(x, y) {
    this.pos = p.createVector(p.random(p.width), p.random(p.height));
    this.target = p.createVector(x, y);
    this.vel = p.createVector(Math.random(), Math.random());
    this.acc = p.createVector();
    this.col = getRandomColour();
    this.r = 8;
    this.maxSpeed = 15;
    this.maxForce = 1;
  }

  Particle.prototype.applyForce = function (f) {
    this.acc.add(f);
  };

  Particle.prototype.behaviors = function () {
    const arrive = this.arrive(this.target);
    const mouse = p.createVector(p.mouseX, p.mouseY);
    const run = this.runaway(mouse);

    arrive.mult(1);
    run.mult(3);

    this.applyForce(run);
    this.applyForce(arrive);
  };

  Particle.prototype.seek = function (target) {
    const desired = p.createVector(
      target.x - this.pos.x,
      target.y - this.pos.y,
    );
    const randomVal = getRandomInt(0, 10);
    const randomSpeed = randomVal * this.maxSpeed;
    desired.setMag(randomSpeed);
    const steer = p.Vector.sub(desired, this.vel);
    steer.limit(this.maxForce);
    return steer;
  };

  Particle.prototype.arrive = function (target) {
    const desired = p.createVector(
      target.x - this.pos.x,
      target.y - this.pos.y,
    );
    const d = desired.mag();
    let speed;
    if (d < 100) {
      speed = p.map(d, 0, 100, 0, this.maxSpeed);
    } else {
      speed = this.maxSpeed;
    }
    desired.setMag(speed);
    const steer = p.createVector(
      desired.x - this.vel.x,
      desired.y - this.vel.y,
    );
    steer.limit(this.maxForce);
    return steer;
  };

  Particle.prototype.runaway = function (target) {
    const desired = p.createVector(
      target.x - this.pos.x,
      target.y - this.pos.y,
    );
    const d = desired.mag();
    if (d < 50) {
      desired.setMag(this.maxSpeed);
      desired.mult(-1);
      const steer = p.createVector(
        desired.x - this.vel.x,
        desired.y - this.vel.y,
      );
      steer.limit(this.maxForce);
      return steer;
    }
    return p.createVector(0, 0);
  };

  Particle.prototype.update = function () {
    this.pos.add(this.vel);
    this.vel.add(this.acc);
    this.acc.mult(0);
  };

  Particle.prototype.render = function () {
    p.stroke(this.col);
    p.strokeWeight(1);
    p.point(this.pos.x, this.pos.y);
  };

  p.preload = () => {
    font = p.loadFont(type);
  };

  p.setup = () => {
    canvas = p.createCanvas(W, H);
    p.textFont(font);
    const bounds = font.textBounds(textToDisplay, (W / 2), (H / 2), fontsize);
    const centerX = (W / 2) - (bounds.w / 2);
    const centerY = (H / 2) + (bounds.h / 2);
    const points = font.textToPoints(textToDisplay, centerX, centerY, fontsize, {
      sampleFactor: 1,
    });

    points.forEach((pt) => {
      const particle = new Particle(pt.x, pt.y);
      particleSystem.push(particle);
    });
  };

  p.draw = () => {
    p.clear();
    particleSystem.forEach((particle) => {
      particle.behaviors();
      particle.update();
      particle.render();
    });
  };
}
